import { ButtonLogo, Media, MediaFooter, MediaOverlay, StickerVariant } from '@canalplus/dive';
import { DIMENSIONS, ImageSize, Ratio } from '@canalplus/mycanal-commons';
import { type CardProps } from '@canalplus/mycanal-sharedcomponent';
import { PersoLists, TitleDisplayMode } from '@canalplus/sdk-hodor';

import classNames from 'classnames/bind';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Sticker from '../../../Sticker/Sticker';
import { REFRESH_PROGRESS_BAR_MS } from '../../../../constants/limits';
import { isOngoing } from '../../../../helpers/card/card-helper';
import { getPublicConfig } from '../../../../helpers/config/config-helper';
import { getHodorSticker } from '../../../../helpers/stickers/stickers-helper';
import { featUpcomingHodorStickerSelector, isDarkModeSelector } from '../../../../store/slices/application-selectors';
import { isImmersiveSelector } from '../../../../store/slices/immersive-selectors';
import { displayTVModeSelector } from '../../../../store/slices/displayMode-selectors';
import { ContentStrateV5 } from '../../../../templates/LandingV5/data/formatter';
import CardConnected, { CardConnectedProps } from '../../../Card/CardConnected';
import Linker from '../../../Linker/Linker';
import type { LinkerData } from '../../../Linker/types';
import ThumborMediaImage from '../../../ThumborMediaImage/ThumborMediaImage';
import styles from '../ContentRowTemplateItem.css';
import { RankItemStandard } from './RankItemStandard';

import DiveProgressBar from '../../../ProgressBar/DiveProgressBar';

const cx = classNames.bind(styles);

export type ContentRowTemplateItemStandardProps = {
  content: ContentStrateV5;
  imageSize?: ImageSize;
  isBackgroundTransparent?: boolean;
  isFromDetail?: boolean;
  isFromLanding?: boolean;
  isRemovableItem?: boolean;
  listType?: PersoLists;
  onClickRemove?: (event: React.MouseEvent, contentID: string, listType: PersoLists) => void;
  ratio: Ratio;
  titleDisplayMode?: TitleDisplayMode;
  isTop10?: boolean;
};

export function ContentRowTemplateItemStandard({
  content,
  titleDisplayMode = TitleDisplayMode.All,
  ratio,
  isFromDetail,
  isFromLanding,
  imageSize = 'normal',
  isBackgroundTransparent = false,
  isRemovableItem = false,
  isTop10 = false,
  listType,
  onClickRemove,
}: ContentRowTemplateItemStandardProps): JSX.Element | null {
  const isFeatUpcomingHodorSticker = useSelector(featUpcomingHodorStickerSelector);
  const isFromImmersive = useSelector(isImmersiveSelector) && !isFromLanding;
  const isDark = useSelector(isDarkModeSelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const hodorSticker = getHodorSticker(content.stickers, isFeatUpcomingHodorSticker);

  const linkerData = useMemo(
    () =>
      ({
        contentID: content?.contentID,
        mainOnClick: content?.onClick,
        context: content?.context,
      }) satisfies LinkerData,
    [content]
  );

  const { startTime, endTime } = content;

  const [isLive, setIsLive] = useState(isOngoing({ startTime, endTime }));

  const updateLiveStatus = useCallback(() => {
    const isOnGoing = isOngoing({ startTime, endTime });
    setIsLive(isOnGoing);
  }, [endTime, startTime]);

  useEffect(() => {
    const updateLiveStatusInterval = setInterval(updateLiveStatus, REFRESH_PROGRESS_BAR_MS);
    return () => clearInterval(updateLiveStatusInterval);
  }, [updateLiveStatus]);

  const isDescriptionVisible =
    titleDisplayMode === TitleDisplayMode.Description || titleDisplayMode === TitleDisplayMode.All;

  const publicConfig = getPublicConfig();

  const URLImage = content.URLImage;
  const { URLImageForDarkMode = URLImage, URLImageForLightMode = URLImage, imageSpecificities } = content;

  const isTitleVisible = titleDisplayMode !== TitleDisplayMode.None;
  const title = isTitleVisible ? content.title : '';
  const subtitle = isTitleVisible && titleDisplayMode !== TitleDisplayMode.TitleOnly ? content.subtitle : '';
  const description = isDescriptionVisible ? content.description : '';
  const image = { default: isDark ? URLImageForDarkMode : URLImageForLightMode };
  const onGoing = startTime ? { startTime, endTime } : undefined;
  const isArticle = content.type === publicConfig.TEMPLATE.CREATIVE_MEDIA;
  const isBottomTitle = !([TitleDisplayMode.LogoTyped, TitleDisplayMode.None] as TitleDisplayMode[]).includes(
    titleDisplayMode
  );

  const ariaLabel = isTop10 ? `Top ${content.rank} ${content.title}` : content.title;

  const dimensions = DIMENSIONS[ratio][imageSize];

  const isClickableCard = !!content.onClick?.URLPage;

  const getVariantSticker = (): StickerVariant | undefined => {
    if (!content.userProgress && isLive && content.isInOffer) {
      return StickerVariant.Live;
    } else if (content.lastDays) {
      return StickerVariant.LastDays;
    }
    return undefined;
  };

  const mediaCard = (
    <Media
      aspectRatio={ratio}
      aria-label={ariaLabel}
      image={<ThumborMediaImage dimensions={dimensions} url={URLImage} alt={content.altImage} />}
      variant={isBackgroundTransparent ? 'ghost' : 'solid'}
      overlay={
        <MediaOverlay
          logo={
            content.URLLogoChannel ? (
              <ThumborMediaImage
                dimensions={isTvDevice ? DIMENSIONS.LOGO_CHANNEL.extraLarge : DIMENSIONS.LOGO_CHANNEL.normal}
                url={content.URLLogoChannel}
                alt={content.altLogoChannel}
              />
            ) : undefined
          }
          progressBar={isLive ? <DiveProgressBar startTime={startTime} endTime={endTime} isLive /> : undefined}
          sticker={
            <Sticker sticker={hodorSticker} startTime={startTime} endTime={endTime} variant={getVariantSticker()} />
          }
        />
      }
      footer={isBottomTitle ? <MediaFooter title={title} subtitle={subtitle} description={description} /> : undefined}
      data-tv-focusable={!isClickableCard}
    />
  );

  // Clickable card
  if (isClickableCard) {
    const itemClassnames = cx('contentRowTemplateItem', {
      'contentRowTemplateItem--carousel': ratio === Ratio.Ratio166 && !isDescriptionVisible,
      'contentRowTemplateItem--immersive': isFromImmersive,
      'contentRowTemplateItem--logoTyped': titleDisplayMode === TitleDisplayMode.LogoTyped,
      'contentRowTemplateItem--logoTyped--top10': titleDisplayMode === TitleDisplayMode.LogoTyped && isTop10,
      'contentRowTemplateItem--detailV5': isFromDetail,
      'contentRowTemplateItem--top10': isTop10,
    });

    const cardProps = {
      ariaLabel,
      description,
      disabledStyle: {
        disabled: !content?.onClick?.URLPage,
        opacityOnPoster: false,
      } satisfies CardProps['disabledStyle'],
      displayName: content.onClick?.displayName,
      image,
      imageSize,
      imageSpecificities,
      isArticle,
      isLogoTyped: content.isLogoTyped,
      logoChannel: { default: content.URLLogoChannel ?? '' },
      onGoing,
      ratio,
      subtitle,
      title,
      titleDisplayMode,
      userProgress: content.userProgress,
      altImage: content.altImage,
      altLogoChannel: content.altLogoChannel,
      isInOffer: content.isInOffer,
      dimensions,
      sticker: hodorSticker,
    } satisfies CardConnectedProps;

    return (
      <li className={itemClassnames} data-ratio={`${ratio}${imageSize}`}>
        <Linker
          className={cx('contentRowTemplateItem__itemLink', {
            'contentRowTemplateItem__itemLink--top10': isTop10,
          })}
          data={linkerData}
          title={ariaLabel}
          ariaLabel={ariaLabel}
        >
          {isTop10 && !!content.rank && <RankItemStandard rank={content.rank} />}
          {imageSpecificities === 'isLogo' ? (
            <ButtonLogo
              as="div"
              logo={
                <ThumborMediaImage url={image.default} dimensions={dimensions} alt={content.altImage} height="auto" />
              }
            />
          ) : (
            <div className={styles['contentRowTemplateItem__itemLink--top10__media']}>
              {/* // TODO : completely switch to Media once the remove icon is available in dive  */}
              {isRemovableItem || isArticle ? (
                <CardConnected
                  {...cardProps}
                  contentID={content.contentID}
                  isRemovableItem={isRemovableItem}
                  lastDays={content.lastDays}
                  listType={listType}
                  onClickRemove={onClickRemove}
                  dataTvFocusable={!content.onClick?.URLPage}
                  isBackgroundTransparent={isBackgroundTransparent}
                />
              ) : (
                mediaCard
              )}
            </div>
          )}
        </Linker>
      </li>
    );
  }

  // Not clickable card
  return (
    <li className={styles.contentRowTemplateItem} data-ratio={`${ratio}${imageSize}`}>
      {imageSpecificities === 'isLogo' ? (
        <ButtonLogo
          as="button"
          logo={<ThumborMediaImage url={image.default} dimensions={dimensions} alt={content.altImage} height="auto" />}
        />
      ) : (
        mediaCard
      )}
    </li>
  );
}
