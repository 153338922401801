import classNames from 'classnames';
import truncate from 'lodash/truncate';
import { useDiveContext } from '../../../context';
import { MediaFooterProps } from './MediaFooter.types';

export const ELLIPSIS = '…';
export const DESCRIPTION_LENGTH_MAX = 250;

/**
 * Renders `Media` related editorial content
 *
 * | Content       | Overflows to `...` when |
 * |---------------|-------------------------|
 * | `title`       | > 2 lines               |
 * | `subtitle`    | > 2 lines               |
 * | `description` | > 250 characters        |
 *
 * @example
 * <MediaFooter
 *   title="Avatar: The Way of Water"
 *   subtitle='Return to Pandora.'
 *   description='Learn the story of the Sully family'
 *  />
 */
export function MediaFooter({ title, subtitle, description }: MediaFooterProps): JSX.Element | null {
  const { isTv } = useDiveContext();

  if (!(title || subtitle || description)) {
    return null;
  }

  return (
    <div
      className={classNames(
        ' font-dt-font-family-system font-dt-font-weight-regular',
        isTv ? 'mt-dt-spacing-200' : 'mt-dt-spacing-100',
        // ! tailwind-base-styles
        '[&>p]:mb-dt-spacing-none'
      )}
    >
      {title && (
        <p
          className={classNames(
            `overflow-hidden line-clamp-2 text-ellipsis`,
            isTv
              ? [
                  'text-dt-font-size-30 leading-dt-font-line-height-36',
                  'text-dt-theme-tv-text-media-block-media-block-title ',
                  'tv-focus-parent:text-dt-theme-tv-text-media-block-media-block-title-focus ',
                ]
              : [
                  'text-dt-font-size-16 leading-dt-font-line-height-20',
                  'text-dt-theme-text-media-block-media-block-title',
                ],
            // ! tailwind-base-styles
            'mt-dt-spacing-none'
          )}
        >
          {title}
        </p>
      )}
      {subtitle && (
        <p
          className={classNames(
            'mt-dt-spacing-25 line-clamp-2 text-ellipsis',
            isTv
              ? [
                  'text-dt-font-size-28 leading-dt-font-line-height-32',
                  'text-dt-theme-tv-text-media-block-media-block-subtitle',
                  'tv-focus-parent:text-dt-theme-tv-text-media-block-media-block-subtitle-focus',
                ]
              : [
                  'text-dt-font-size-14 leading-dt-font-line-height-18',
                  'text-dt-theme-text-media-block-media-block-subtitle',
                ]
          )}
        >
          {subtitle}
        </p>
      )}
      {description && (
        <p
          className={classNames(
            isTv
              ? [
                  'text-dt-font-size-28 leading-dt-font-line-height-32 mt-dt-spacing-100',
                  'text-dt-theme-tv-text-media-block-media-block-description',
                  'tv-focus-parent:text-dt-theme-tv-text-media-block-media-block-description-focus',
                ]
              : [
                  'text-dt-font-size-14 leading-dt-font-line-height-18 mt-dt-spacing-75',
                  'text-dt-theme-text-media-block-media-block-description',
                ]
          )}
        >
          {truncate(description, { length: DESCRIPTION_LENGTH_MAX, omission: ELLIPSIS })}
        </p>
      )}
    </div>
  );
}
