import classNames from 'classnames';
import { cloneElement } from 'react';
import { useDiveContext } from '../../../context';

import { ButtonIconProps } from '../../Actions/ButtonIcon/ButtonIcon.types';
import { ProgressBarLinearProps } from '../../ProgressBar/ProgressBarLinear/ProgressBarLinear.types';
import { StickerProps } from '../../Sticker/Sticker.types';
import { MediaOverlayProps } from './MediaOverlay.types';

/**
 * Renders an overlay above MediaImage/MediaVideo, containing graphical
 * (Sticker, Logo, Progress Bar) and editorial (Title) content.
 *
 * @example
 * <MediaOverlay
 *   title="Lorem"
 *   logo="MediaImage" {...LOGO.CanalPlus} />,
 *   sticker: <Sticker label="Sticker label" />,
 *   progressBar: <ProgressBarLinear value={75} />,
 * />
 */
export default function MediaOverlay({
  title,
  subtitle,
  logo,
  progressBar,
  sticker,
  buttonIcon,
  showDarkOverlay = false,
  'data-testid': dataTestId,
}: MediaOverlayProps): JSX.Element {
  const hasTitleOrProgressBar = !!title || !!progressBar;
  const hasEditorialInfo = !!logo || !!title || !!subtitle;
  const { isTv } = useDiveContext();
  const LOGO_DIMENSIONS: React.ImgHTMLAttributes<HTMLImageElement> = isTv
    ? { width: '88px', height: '66px' }
    : { width: '48px', height: '36px' };

  return (
    <div
      className={classNames('z-10', {
        'size-auto bg-dt-theme-background-episode-list-episode-list-unavailable': showDarkOverlay,
      })}
      data-testid={dataTestId}
    >
      {sticker &&
        cloneElement<StickerProps>(sticker, {
          className: classNames(
            'z-40 absolute left-auto',
            isTv
              ? /* Max Sticker width set to 100% of container minus both:
              - Horizontal offset of 16px (1rem) on TV, 12px (0.75rem) on Desktop (L#46-47)
              - Horizontal padding of 12px (+0.75rem) on TV, 8px (+0.5rem) on Desktop (Sticker.cva.ts L#16-17)
              */
                'max-w-[calc(100%-1.75rem)] top-8 right-8'
              : 'max-w-[calc(100%-1.25rem)] top-4 right-4'
          ),
        })}
      {buttonIcon &&
        cloneElement<ButtonIconProps>(buttonIcon, {
          className: classNames(
            'p-dt-spacing-none z-40 border-dt-border-none absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center w-full',
            buttonIcon.props?.className
          ),
          // Render ButtonIcon as a purely visual (non actionable) div
          as: 'div',
        })}
      {(hasEditorialInfo || progressBar) && (
        <div
          className={classNames(
            'absolute box-border bottom-0 w-full h-1/2 mt-auto flex flex-col justify-end',
            isTv && !progressBar ? 'pb-dt-spacing-50 px-dt-spacing-100' : 'p-dt-spacing-100',
            {
              // Background gradient for Title|Progress
              'top-auto z-20 bg-gradient-to-t from-dt-theme-gradient-media-overlay-gradient-gradient-start to-dt-theme-gradient-media-overlay-gradient-gradient-end':
                hasTitleOrProgressBar,
              // Background gradient for Logo
              'before:bottom-0 before:left-0 before:absolute before:size-full': logo,
            },
            {
              // Use lighter Logo background gradient when combined with gradient from Title|Progress
              'before:bg-media-overlay-gradient-logo': logo || hasTitleOrProgressBar,
            },

            // ! tailwind-base-styles
            "before:content-['']"
          )}
        >
          {hasEditorialInfo && (
            <div className="relative z-20 w-full flex items-center" style={{ height: LOGO_DIMENSIONS.height }}>
              {logo && cloneElement(logo, { ...LOGO_DIMENSIONS })}
              {title && (
                <div
                  className={classNames(
                    'box-border flex flex-col w-full overflow-hidden',
                    '[&>p]:overflow-hidden [&>p]:overflow-ellipsis [&>p]:whitespace-nowrap [&>p]:m-dt-spacing-none',
                    'text-dt-theme-text-media-block-media-block-inside-title',
                    isTv
                      ? 'text-dt-font-size-28 leading-dt-font-line-height-32 pl-dt-spacing-100'
                      : 'text-dt-font-size-14 leading-dt-font-line-height-18 pl-dt-spacing-50',
                    // ! tailwind-base-styles
                    'font-dt-font-family-system font-dt-font-weight-regular'
                  )}
                >
                  <p>{title}</p>
                  {subtitle && (
                    <p className="text-dt-theme-text-media-block-media-block-inside-description pb-dt-spacing-100">
                      {subtitle}
                    </p>
                  )}
                </div>
              )}
            </div>
          )}
          {progressBar &&
            cloneElement<ProgressBarLinearProps>(progressBar, {
              className: classNames('mt-dt-spacing-25 top-auto z-20'),
              shouldForceDarkTheme: true,
            })}
        </div>
      )}
    </div>
  );
}
