import { DIMENSIONS, ImageSize, Ratio } from '@canalplus/mycanal-commons';
import { TitleDisplayMode } from '@canalplus/sdk-hodor';
import { ApiV2BroadcastChannel } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/live_grid/definitions';
import classNames from 'classnames/bind';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { REFRESH_PROGRESS_BAR_MS } from '../../../../constants/limits';
import { StrateMode } from '../../../../constants/strates';
import { getChannelCurrentLiveContent } from '../../../../helpers/contents/contents-helper';
import { blackListedChannelsSelector, isDarkModeSelector } from '../../../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../../../store/slices/displayMode-selectors';
import CardConnected from '../../../Card/CardConnected';
import { CardProps } from '../../../Card/types';
import Linker from '../../../Linker/Linker';
import StartOverButton from '../../../StartOverButton/StartOverButton';
import styles from '../ContentRowTemplateItem.css';
import { HeadlineLiveTv } from './HeadlineLiveTv';

const cx = classNames.bind(styles);

export type ContentRowTemplateItemLiveTvProps = {
  channel: ApiV2BroadcastChannel;
  isHeadline?: boolean;
  imageSize?: ImageSize;
  ratio: Ratio;
  titleDisplayMode?: TitleDisplayMode;
};

export function ContentRowTemplateItemLiveTv({
  channel,
  titleDisplayMode = TitleDisplayMode.All,
  isHeadline = false,
  ratio,
  imageSize = 'normal',
}: ContentRowTemplateItemLiveTvProps): JSX.Element | null {
  const isTvDevice = useSelector(displayTVModeSelector);
  const blackListedChannels = useSelector(blackListedChannelsSelector);
  const isDark = useSelector(isDarkModeSelector);
  const updateLiveContentIntervalId = useRef<NodeJS.Timeout>();
  const [liveContent, setLiveContent] = useState(getChannelCurrentLiveContent(channel));

  const updateLiveContent = useCallback(() => {
    const newLiveContent = getChannelCurrentLiveContent(channel);
    setLiveContent(newLiveContent);
  }, [channel]);

  useEffect(() => {
    updateLiveContentIntervalId.current = setInterval(() => {
      updateLiveContent();
    }, REFRESH_PROGRESS_BAR_MS);

    return () => {
      if (updateLiveContentIntervalId.current) {
        clearInterval(updateLiveContentIntervalId.current);
      }
    };
  }, [updateLiveContent]);

  // Check if channel is banned or content is not on air to delete obsolete card
  if (!liveContent || (channel.epgID && blackListedChannels?.includes(channel.epgID))) {
    return null;
  }

  const {
    URLImage,
    URLImageForDarkMode = URLImage,
    URLImageForLightMode = URLImage,
    URLLogoChannel,
    startTime,
    endTime,
    altImage,
    altLogoChannel,
    isInOffer,
    contentID,
    lastDays,
  } = liveContent;

  const isTitleVisible = titleDisplayMode !== TitleDisplayMode.None;
  const title = isTitleVisible ? liveContent.title : '';
  const subtitle = isTitleVisible && titleDisplayMode !== TitleDisplayMode.TitleOnly ? liveContent.subtitle : '';
  const image = { default: isDark ? URLImageForDarkMode : URLImageForLightMode };
  const logoChannel = { default: URLLogoChannel ?? '' };
  const onGoing = startTime ? { startTime, endTime } : undefined;

  const disabledStyle = {
    disabled: !liveContent?.onClick?.URLPage,
    opacityOnPoster: false,
  } satisfies CardProps['disabledStyle'];

  const dimensions = DIMENSIONS[ratio][imageSize];

  const card = (
    <CardConnected
      ariaLabel={liveContent.title}
      disabledStyle={disabledStyle}
      displayName={liveContent.onClick?.displayName}
      image={image}
      imageSize={imageSize}
      isLogoTyped={liveContent.isLogoTyped}
      logoChannel={logoChannel}
      onGoing={onGoing}
      ratio={ratio}
      subtitle={subtitle}
      title={title}
      titleDisplayMode={titleDisplayMode}
      altImage={altImage}
      altLogoChannel={altLogoChannel}
      isInOffer={isInOffer}
      dimensions={dimensions}
      action={
        <StartOverButton
          context={liveContent.onClick?.trackingContext}
          content={liveContent}
          title={liveContent?.title}
        />
      }
      cardType={StrateMode.LiveTv}
      contentID={contentID}
      lastDays={lastDays}
      dataTvFocusable
    />
  );

  const itemClassnames = cx('contentRowTemplateItem', {
    'contentRowTemplateItem--logoTyped': titleDisplayMode === TitleDisplayMode.LogoTyped,
  });

  const className = isHeadline ? styles.contentRowTemplateItem__fullWidth : itemClassnames;

  return (
    <li className={className} data-ratio={`${ratio}${imageSize}`}>
      <Linker
        className={styles.contentRowTemplateItem__itemLink}
        data={{
          mainOnClick: liveContent.onClick,
          ...liveContent,
        }}
        title={title}
        aria-label={title}
        objKey={StrateMode.LiveTv}
      >
        {isHeadline && !isTvDevice ? (
          <HeadlineLiveTv title={title} subtitle={subtitle} summary={liveContent.summary}>
            {card}
          </HeadlineLiveTv>
        ) : (
          card
        )}
      </Linker>
    </li>
  );
}
